import React from "react"
import { Link } from "gatsby"
import DarkModeToggle from "./dark-mode-toggle"

class Navigation extends React.Component {
    render() {
        return (
            <div className="container py-5">
                <nav className="main-navbar d-flex flex-md-row flex-column navbar navbar-expand navbar-custom">
                    <span className="navbar-brand main-color-title">
                        <Link to="/">
                            <svg className="main-social-svg" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 456 456">
                                <path id="Imported Path" fill="true" strokeWidth="1" d="M 121.60,349.58C 121.60,349.58 182.39,349.58 182.39,349.58 182.39,349.59 182.39,349.59 182.39,349.60 182.39,374.79 202.81,395.20 228.00,395.20 253.19,395.20 273.61,374.79 273.61,349.60 273.61,337.48 268.89,326.47 261.18,318.31 261.18,318.31 260.11,317.22 260.11,317.22 260.11,317.22 227.99,284.22 227.99,284.22 227.99,284.22 202.85,258.40 202.85,258.40 202.85,258.40 287.85,258.40 287.85,258.40 287.85,258.40 395.20,369.19 395.20,369.19 395.20,369.19 395.20,0.03 395.20,0.03 395.20,0.03 456.00,0.03 456.00,0.03 456.00,0.03 456.00,455.97 456.00,455.97 456.00,455.97 395.20,455.97 395.20,455.97 395.20,455.97 327.74,386.68 327.74,386.68 312.71,427.15 273.73,455.97 228.01,455.97 169.24,455.97 121.60,408.34 121.60,349.58 121.60,349.58 121.60,349.58 121.60,349.58 ZM 334.39,106.42C 334.39,106.42 273.60,106.42 273.60,106.42 273.60,106.42 273.60,106.42 273.60,106.42 273.60,81.23 253.18,60.82 228.00,60.82 202.82,60.82 182.40,81.23 182.40,106.42 182.40,118.52 187.12,129.53 194.82,137.69 194.82,137.69 195.88,138.78 195.88,138.78 195.88,138.78 228.00,171.78 228.00,171.78 228.00,171.78 253.14,197.60 253.14,197.60 253.14,197.60 168.14,197.60 168.14,197.60 168.14,197.60 60.79,86.81 60.79,86.81 60.79,86.81 60.79,455.97 60.79,455.97 60.79,455.97 0.00,455.97 0.00,455.97 0.00,455.97 0.00,0.03 0.00,0.03 0.00,0.03 60.79,0.03 60.79,0.03 60.79,0.03 128.25,69.32 128.25,69.32 143.28,28.85 182.26,0.03 227.98,0.03 286.75,0.03 334.39,47.66 334.39,106.42 334.39,106.42 334.39,106.42 334.39,106.42 Z" />
                            </svg>
                        </Link>
                    </span>
                    <div className="d-flex flex-row">
                        <Link className="nav-link" to="/">Home</Link>
                        <Link className="nav-link" to="/blog">Blog</Link>
                        <Link className="nav-link" to="/contact">Contact</Link>
                        <Link className="nav-link" to="/particle-simulator">Particle-Simulator</Link>
                        <DarkModeToggle />
                    </div>
                </nav>
            </div>
        )
    }
}

export default Navigation
